<template>
  <v-container>
    <v-card v-show="loading" elevation="64" min-height="600">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular color="primary" indeterminate :size="70" :width="7" />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar centered
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

   <v-card elevation="12" min-height="600">
      <v-card-title>Vecchio patrimonio</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="entered">
          
          <v-text-field
            label="Numero inventario"
            ref="myinput"
            prefix="B7"
            v-model="inventario"
          ></v-text-field>
          <v-btn @click="entered">vai</v-btn>
        </v-form>
      </v-card-text>
   

  <!-- <v-card elevation="12" min-height="600">
    <v-card-title>Vecchio patrimonio</v-card-title>
    <v-card-text>
      <v-text-field
        label="Numero inventario"
        ref="myinput"
        prefix="B7"
        v-model="inventario"
        @keydown.enter="entered"
      ></v-text-field>
    </v-card-text> -->


<v-col v-if="result" cols="12">

<v-card-text >
  <v-card-title>Bene trovato:</v-card-title>
<v-row v-for="(value,field) in result.old" :key="field" no-gutters dense>
    <v-spacer></v-spacer>
  <v-col cols="3" class="text-right" align="end">{{ field}} :</v-col>
  <v-col cols="8"> {{ value}}</v-col>
</v-row>
</v-card-text>
 
</v-col>
   </v-card>   
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Old",
  data: () => {
    return {
      loading: false,
      inventario: "",
      result: "",
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      timeout: 3000,
      myobj: {
        type: "",
        cdc: "",
        inventario: "",
      },
    };
  },
  mounted() {
    // this.$refs.myinput.$el.click();
    this.$nextTick(() => this.$refs.myinput.$refs.input.focus());
    // console.log(this.$refs)
  },
  methods: {
    entered() {

      //let type = this.inventario.charAt(0);
      //let cdc = ('7');
      //let inv = Number(this.inventario.substring(2));
      //console.log(type, cdc, inv);

      // console.log(type !== "B", Number.isInteger(cdc), Number.isInteger(inv));
      //if (type !== "B" || !Number.isInteger(cdc) || !Number.isInteger(inv)) {
      //  this.result = this.inventario + ": invalid";
      //  this.inventario = '';
      //} else {
      //  this.result = "valid";
      this.roomsLoading = true;

      if (this.inventario.includes('B7')) {
      
         this.inventario = this.inventario.replace('B7','')

      }
 
      this.inventario = parseInt(this.inventario)
      
      if (  this.inventario.toString().length > 4  ) {

        this.inventario = '';
        this.snackbarColor = "error";
        this.permSnackbar = true;
        this.errorText = 'Valore maggiore di 4 cifre. Riprovare';

      } else if (isNaN(this.inventario)) {

        this.inventario = '';
        this.snackbarColor = "error";
        this.permSnackbar = true;
        this.errorText = 'Non inserire lettere o campi vuoti, solo numeri';

      } else {
      // this.myobj.type = type;
      // this.myobj.cdc = cdc;
      // this.myobj.inventario = inv;
      
  

      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/old/" + this.inventario + '/7', {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          // console.log(response)
          this.result = response.data;
          this.inventario = '';
          this.loading = false;

        })
        .catch(() => {
        this.snackbarColor = "error";
        this.permSnackbar = true;
        this.errorText = 'Errore o bene non trovato';
          this.inventario = '';
          // console.log(err);
        });

        this.loading = false;


      }
    },
  },
};
</script>
